<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.create_time')"
                :placeholder="$t('labels.create_time')"
                name="created_at"
                sort-name="created_at"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectEmployee
                :label="$t('labels.employee_create')"
                :placeholder="$t('labels.employee_create')"
                name="identity_create"
                sort-name="identity_create"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :label="$t('labels.time')"
                :placeholder="$t('labels.time')"
                :options="programTimeOptions"
                name="type"
                sort-name="type"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :options="statusOptions"
                name="status"
                sort-name="status"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectPos
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                name="id_pos"
                sort-name="pos_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="barcode"
                sort-name="barcode"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilterFromTo
                :label="$t('labels.quantity')"
                :placeholder="$t('labels.quantity')"
                name="quantity"
                sort-name="quantity"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.note')"
                :placeholder="$t('labels.note')"
                name="note"
                sort-name="note"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.from')"
                :placeholder="$t('labels.from')"
                name="start_time"
                sort-name="start_time"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.to')"
                :placeholder="$t('labels.to')"
                name="end_time"
                sort-name="end_time"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilterFromTo
                :label="$t('labels.available_quantity_1')"
                :placeholder="$t('labels.available_quantity_1')"
                name="quantity"
                sort-name="quantity"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.display_command')"
                :placeholder="$t('labels.display_command')"
                name="command_time"
                sort-name="command_time"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.process_time')"
                :placeholder="$t('labels.process_time')"
                name="process_time"
                sort-name="process_time"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <v-btn small color="success" @click="showRequestDialog">{{
                $t("labels.add")
              }}</v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="`i_${item.id}`" class="text-center">
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>{{ item.identity_name }}</td>
            <td :class="item.program_color">{{ item.program_text }}</td>
            <td :class="item.status_color">{{ item.status_text }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.pos_code }}</td>
            <td>{{ item.customer_goods_barcode }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.note }}</td>
            <td>{{ formatDateTime(item.start_time) }}</td>
            <td>{{ formatDateTime(item.end_time) }}</td>
            <td>{{ item.available_quantity || 0 }}</td>
            <td>
              {{
                item.is_active_command && item.active_command_time
                  ? formatDateTime(item.active_command_time)
                  : ""
              }}
            </td>
            <td>
              {{
                item.is_active_command && item.active_command_time
                  ? subTimeHuman(item.active_command_time)
                  : ""
              }}
            </td>
            <td>
              <v-btn
                v-if="item.type > 0 && item.status > 0"
                :disabled="item.status === 4"
                color="error"
                x-small
                @click="cancelRequest(item)"
                >Hủy</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="text-center mt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="4"
      ></v-pagination>
    </div>

    <v-dialog v-model="createRequestDialog" persistent max-width="100vw">
      <CreateDisplayRequest
        v-if="createRequestDialog"
        @cancel="hideRequestDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>
<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "DisplayRequest",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectPos: () => import("@/components/table/SelectPos"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    CreateDisplayRequest: () =>
      import("@/components/pos_goods/CreateDisplayRequest"),
  },
  data: () => ({
    items: [],
    filters: {},
    page: 1,
    totalPage: 1,
    isLoading: false,
    yesNoOptions: [...YES_NO_OPTIONS],
    createRequestDialog: false,
  }),
  computed: {
    programTimeOptions() {
      return [
        {
          text: "Đang diễn ra",
          color: "success--text",
          value: 2,
        },
        {
          text: "Sắp diễn ra",
          color: "primary--text",
          value: 1,
        },
        {
          text: "Đã kết thúc",
          color: "error--text",
          value: 0,
        },
      ];
    },
    statusOptions() {
      //0: huy, 1: cho xu ly, 2: thieu hang, 3: da tao lenh, 4: dang xu ly, 5: da xu ly
      return [
        {
          text: "Chờ xử lý",
          color: "",
          value: 1,
        },
        {
          text: "Thiếu hàng",
          color: "warning--text",
          value: 2,
        },
        {
          text: "Đã tạo lệnh",
          color: "",
          value: 3,
        },
        {
          text: "Đang xử lý",
          color: "",
          value: 4,
        },
        {
          text: "Đã xử lý",
          color: "success--text",
          value: 5,
        },
        {
          text: "Đang tìm hàng",
          color: "warning--text",
          value: 6,
        },
        {
          text: "Đã hủy",
          color: "error--text",
          value: 0,
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getStatusText(item) {
      return this.statusOptions.find((p) => p.value === item.status); //item.type === 2 || item.status === 0 ? this.statusOptions.find(p => p.value === item.status) : {}
    },
    getProgramText(item) {
      return item.status > 0
        ? this.programTimeOptions.find((p) => p.value === item.type)
        : {};
    },
    getList: debounce(function () {
      httpClient
        .post("/pos-get-display-request", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((r) => {
            const status = this.getStatusText(r);
            r.status_text = status.text;
            r.status_color = status.color;

            const program = this.getProgramText(r);
            r.program_text = program.text;
            r.program_color = program.color;

            return r;
          });
        });
    }, 1000),
    async cancelRequest(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_program"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-cancel-display-request", {
          id: item.id,
        });
        this.isLoading = false;
        this.getList();
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showRequestDialog() {
      this.createRequestDialog = true;
    },
    hideRequestDialog() {
      this.createRequestDialog = false;
    },
  },
};
</script>

<style scoped></style>
